<template>
  <div class="w-full flex flex-row justify-center">
    <div class="w-full lg:w-3/4 flex flex-col items-center justify-center gap-3 mb-3 lg:mb-6" >
      <base-icon :width="isMobile?60:120" :height="isMobile?60:120" :name="iconName" color="fill-grayedLight"/>
      <div class="text-lg w-full text-center"
        :class="{'text-badgeError':type==='error', 'text-badgeInfo':type==='warning'}"
      >
        <slot name="section1"/>
      </div>
      <div v-if="sectionCount>1"
        class="text-lg w-full text-center"
        :class="{'text-badgeError':type==='error', 'text-badgeInfo':type==='warning', '-mt-3':sectionCount>2}"
      >
        <slot name="section2"/>
      </div>
      <div v-if="sectionCount>2"
        class="text-lg w-full text-center"
        :class="{'text-badgeError':type==='error', 'text-badgeInfo':type==='warning'}"
      >
        <slot name="section3"/>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from "@/utils/icons/BaseIcon";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
export default {
  name: "Notification",
  components: {BaseIcon},
  computed:{
    isMobile() {
      return checkIsMobileDevice();
    },
  },
  props: {
    iconName: String,
    sectionCount: Number,
    type:String,//error,warning
  }
}

</script>
